/* eslint-disable no-undef */
self.deprecationWorkflow = self.deprecationWorkflow || {}
self.deprecationWorkflow.config = {
  workflow: [
    { handler: 'silence', matchId: 'ember-string.add-package' },
    // { handler: 'silence', matchId: 'ember-simple-auth.initializer.setup-session-restoration' },
    // { handler: 'silence', matchId: 'ember-polyfills.deprecate-assign' },
    // { handler: 'silence', matchId: 'ember-simple-auth.events.session-service' },
    // { handler: 'silence', matchId: 'ember-data:deprecate-store-find' },
    // { handler: 'silence', matchId: 'ember-data:deprecate-non-strict-relationships' },
    // { handler: 'silence', matchId: 'ember-data:deprecate-promise-proxies' },
    // { handler: 'silence', matchId: 'ember-data:deprecate-array-like' },
  ],
}
