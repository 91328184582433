define("ember-modifier/-private/compat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.consumeArgs = void 0;
  _exports.isFactory = isFactory;
  function isFactory(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _factoryOrClass) {
    return !true;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const noop = () => {};

  /**
   * Consume each positional and named argument to entangle it in autotracking and
   * enable updates.
   *
   * This is a temporary workaround for a change in the autotracking semantics of
   * the args proxy introduced in `v3.22`. What changed is that arguments are no
   * longer eagerly consumed. Didn’t use an argument? Then updates won’t be run
   * when its value changes. This workaround reproduces the previous behaviour to
   * avoid introducing a breaking change until a suitable transition path is made
   * available.
   */
  let consumeArgs = _exports.consumeArgs = noop;
  if (true) {
    _exports.consumeArgs = consumeArgs = function ({
      positional,
      named
    }) {
      for (let i = 0; i < positional.length; i++) {
        positional[i];
      }
      Object.values(named);
    };
  }
});