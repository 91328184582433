define("ember-inline-svg/helpers/inline-svg", ["exports", "@ember/template", "@ember/debug", "@ember/object", "ember-inline-svg/utils/general"], function (_exports, _template, _debug, _object, _general) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inlineSvg = inlineSvg;
  function inlineSvg(svgs, path, options) {
    var jsonPath = (0, _general.dottify)(path);
    var svg = (0, _object.get)(svgs, jsonPath);

    // TODO: Ember.get should return `null`, not `undefined`.
    // if (svg === null && /\.svg$/.test(path))
    if (typeof svg === "undefined" && /\.svg$/.test(path)) {
      svg = (0, _object.get)(svgs, jsonPath.slice(0, -4));
    }
    (true && !(svg) && (0, _debug.assert)("No SVG found for " + path, svg));
    svg = (0, _general.applyClass)(svg, options.class);
    svg = (0, _general.applyTitle)(svg, options.title);
    return (0, _template.htmlSafe)(svg);
  }
});